define("discourse/plugins/chat/discourse/components/chat-message-reaction", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "ember-modifier", "truth-helpers", "discourse/helpers/concat-class", "discourse/lib/text", "discourse/plugins/chat/discourse/lib/get-reaction-text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _template, _emberModifier, _truthHelpers, _concatClass, _text, _getReactionText, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageReaction extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "tooltip", [_service.service]))();
    #tooltip = (() => (dt7948.i(this, "tooltip"), void 0))();
    registerTooltip = (() => (0, _emberModifier.modifier)(element => {
      if (this.args.disableTooltip || !this.popoverContent?.length) {
        return;
      }
      const instance = this.tooltip.register(element, {
        content: (0, _template.htmlSafe)(this.popoverContent),
        identifier: "chat-message-reaction-tooltip",
        animated: false,
        placement: "top",
        fallbackPlacements: ["bottom"],
        triggers: this.site.mobileView ? ["hold"] : ["hover"]
      });
      return () => {
        instance.destroy();
      };
    }))();
    get showCount() {
      return this.args.showCount ?? true;
    }
    get emojiString() {
      return `:${this.args.reaction.emoji}:`;
    }
    get emojiUrl() {
      return (0, _text.emojiUrlFor)(this.args.reaction.emoji);
    }
    handleClick(event) {
      event.stopPropagation();
      this.args.onReaction?.(this.args.reaction.emoji, this.args.reaction.reacted ? "remove" : "add");
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleClick", [_object.action]))();
    get popoverContent() {
      if (!this.args.reaction.count || !this.args.reaction.users?.length) {
        return;
      }
      return (0, _text.emojiUnescape)((0, _getReactionText.getReactionText)(this.args.reaction, this.currentUser));
    }
    static #_5 = (() => dt7948.n(this.prototype, "popoverContent", [_tracking.cached]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (and @reaction this.emojiUrl)}}
          <button
            {{on "click" this.handleClick passive=true}}
            {{this.registerTooltip}}
            type="button"
            title={{this.emojiString}}
            data-emoji-name={{@reaction.emoji}}
            tabindex="0"
            class={{concatClass
              "chat-message-reaction"
              (if @reaction.reacted "reacted")
            }}
          >
            <img
              loading="lazy"
              class="emoji"
              width="20"
              height="20"
              alt={{this.emojiString}}
              src={{this.emojiUrl}}
            />
    
            {{#if (and this.showCount @reaction.count)}}
              <span class="count">{{@reaction.count}}</span>
            {{/if}}
          </button>
        {{/if}}
      
    */
    {
      "id": "oGOzpBb9",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],[30,0,[\"emojiUrl\"]]],null],[[[1,\"      \"],[11,\"button\"],[16,\"title\",[30,0,[\"emojiString\"]]],[16,\"data-emoji-name\",[30,1,[\"emoji\"]]],[24,\"tabindex\",\"0\"],[16,0,[28,[32,1],[\"chat-message-reaction\",[52,[30,1,[\"reacted\"]],\"reacted\"]],null]],[24,4,\"button\"],[4,[32,2],[\"click\",[30,0,[\"handleClick\"]]],[[\"passive\"],[true]]],[4,[30,0,[\"registerTooltip\"]],null,null],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"loading\",\"lazy\"],[14,0,\"emoji\"],[14,\"width\",\"20\"],[14,\"height\",\"20\"],[15,\"alt\",[30,0,[\"emojiString\"]]],[15,\"src\",[30,0,[\"emojiUrl\"]]],[12],[13],[1,\"\\n\\n\"],[41,[28,[32,0],[[30,0,[\"showCount\"]],[30,1,[\"count\"]]],null],[[[1,\"          \"],[10,1],[14,0,\"count\"],[12],[1,[30,1,[\"count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@reaction\"],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-reaction.js",
      "scope": () => [_truthHelpers.and, _concatClass.default, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageReaction;
});