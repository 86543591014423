import { template as template_dcf6216318eb454a8e8daa9abaa6db23 } from "@ember/template-compiler";
const FKControlMenuContainer = template_dcf6216318eb454a8e8daa9abaa6db23(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
