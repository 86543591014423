import { template as template_fc865936452c471a84c556bbb0f61c2a } from "@ember/template-compiler";
const FKText = template_fc865936452c471a84c556bbb0f61c2a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
