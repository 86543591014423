define("discourse/plugins/chat/discourse/components/chat/message-creator/new-group", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/members-count", "discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _service, _truthHelpers, _dButton, _ajaxError, _discourseI18n, _membersCount, _membersSelector, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewGroup extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "newGroupTitle", [_tracking.tracked], function () {
      return "";
    }))();
    #newGroupTitle = (() => (dt7948.i(this, "newGroupTitle"), void 0))();
    placeholder = (() => (0, _discourseI18n.i18n)("chat.direct_message_creator.group_name"))();
    get membersCount() {
      return this.args.members?.reduce((acc, member) => {
        if (member.type === "group") {
          return acc + member.model.chat_enabled_user_count;
        } else {
          return acc + 1;
        }
      }, 0);
    }
    get maxMembers() {
      return this.siteSettings.chat_max_direct_message_users;
    }
    async createGroup() {
      try {
        const usernames = this.args.members.filter(member => member.type === "user").mapBy("model.username");
        const groups = this.args.members.filter(member => member.type === "group").mapBy("model.name");
        const channel = await this.chat.createDmChannel({
          usernames,
          groups
        }, {
          name: this.newGroupTitle
        });
        if (!channel) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel.routeModels);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "createGroup", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-creator__new-group-container">
          <div class="chat-message-creator__new-group">
            <div class="chat-message-creator__new-group-header-container">
              <div class="chat-message-creator__new-group-header">
                <Input
                  name="channel-name"
                  class="chat-message-creator__new-group-header__input"
                  placeholder={{this.placeholder}}
                  @value={{this.newGroupTitle}}
                />
    
                <MembersCount
                  @count={{this.membersCount}}
                  @max={{this.siteSettings.chat_max_direct_message_users}}
                />
              </div>
            </div>
    
            <MembersSelector
              @members={{@members}}
              @channel={{@channel}}
              @onChange={{@onChangeMembers}}
              @close={{@close}}
              @cancel={{@cancel}}
              @membersCount={{this.membersCount}}
              @maxReached={{gte this.membersCount this.maxMembers}}
            />
    
            {{#if @members.length}}
              <div class="chat-message-creator__new-group-footer-container">
                <div class="chat-message-creator__new-group-footer">
                  <DButton
                    class="btn-primary btn-flat"
                    @label="cancel"
                    @action={{@cancel}}
                  />
                  <DButton
                    class="btn-primary create-chat-group"
                    @label="chat.new_message_modal.create_new_group_chat"
                    @action={{this.createGroup}}
                  />
    
                </div>
              </div>
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "lvfEZ0YN",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__new-group-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__new-group\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__new-group-header-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-message-creator__new-group-header\"],[12],[1,\"\\n            \"],[8,[32,0],[[24,3,\"channel-name\"],[24,0,\"chat-message-creator__new-group-header__input\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]]],[[\"@value\"],[[30,0,[\"newGroupTitle\"]]]],null],[1,\"\\n\\n            \"],[8,[32,1],null,[[\"@count\",\"@max\"],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@members\",\"@channel\",\"@onChange\",\"@close\",\"@cancel\",\"@membersCount\",\"@maxReached\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,0,[\"membersCount\"]],[28,[32,3],[[30,0,[\"membersCount\"]],[30,0,[\"maxMembers\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"length\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-message-creator__new-group-footer-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat-message-creator__new-group-footer\"],[12],[1,\"\\n              \"],[8,[32,4],[[24,0,\"btn-primary btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,5]]],null],[1,\"\\n              \"],[8,[32,4],[[24,0,\"btn-primary create-chat-group\"]],[[\"@label\",\"@action\"],[\"chat.new_message_modal.create_new_group_chat\",[30,0,[\"createGroup\"]]]],null],[1,\"\\n\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@members\",\"@channel\",\"@onChangeMembers\",\"@close\",\"@cancel\"],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/new-group.js",
      "scope": () => [_component2.Input, _membersCount.default, _membersSelector.default, _truthHelpers.gte, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NewGroup;
});