define("discourse/plugins/chat/discourse/components/chat/navbar/close-thread-button", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarCloseThreadButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.site.desktopView}}
          <LinkTo
            class="c-navbar__close-thread-button btn-transparent btn btn-icon no-text"
            @route="chat.channel"
            @models={{@thread.channel.routeModels}}
            title={{i18n "chat.thread.close"}}
          >
            {{icon "xmark"}}
          </LinkTo>
        {{/if}}
      
    */
    {
      "id": "2BnSdSyQ",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__close-thread-button btn-transparent btn btn-icon no-text\"],[16,\"title\",[28,[32,1],[\"chat.thread.close\"],null]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"channel\",\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,2],[\"xmark\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@thread\"],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/close-thread-button.js",
      "scope": () => [_routing.LinkTo, _discourseI18n.i18n, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarCloseThreadButton;
});