define("discourse/plugins/chat/discourse/components/chat/message-creator/search", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/constants", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/chatables-loader", "discourse/plugins/chat/discourse/components/chat/message-creator/list", "discourse/plugins/chat/discourse/components/chat/message-creator/list-handler", "discourse/plugins/chat/discourse/components/chat/message-creator/search-input", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _service, _dButton, _ajaxError, _environment, _debounce, _discourseI18n, _constants, _chatablesLoader, _list, _listHandler, _searchInput, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageCreatorSearch extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatables", [_tracking.tracked], function () {
      return [];
    }))();
    #chatables = (() => (dt7948.i(this, "chatables"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "highlightedChatable", [_tracking.tracked]))();
    #highlightedChatable = (() => (dt7948.i(this, "highlightedChatable"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "term", [_tracking.tracked]))();
    #term = (() => (dt7948.i(this, "term"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    get items() {
      const items = [];
      if (this.loading) {
        return items;
      }
      if (!this.term?.length) {
        items.push({
          identifier: "new-group",
          type: "list-action",
          label: (0, _discourseI18n.i18n)("chat.new_message_modal.new_group_chat"),
          enabled: true,
          icon: "users",
          id: "new-group-chat"
        });
      }
      return [...items, ...this.chatables];
    }
    prefillAddMembers(item) {
      this.args.onChangeMode(_constants.MODES.new_group, [item]);
    }
    static #_7 = (() => dt7948.n(this.prototype, "prefillAddMembers", [_object.action]))();
    highlightChatable(chatable) {
      this.highlightedChatable = chatable;
    }
    static #_8 = (() => dt7948.n(this.prototype, "highlightChatable", [_object.action]))();
    async selectChatable(item) {
      switch (item.type) {
        case "list-action":
          this.args.onChangeMode(_constants.MODES.new_group);
          break;
        case "user":
          if (!item.enabled) {
            return;
          }
          await this.startOneToOneChannel(item.model.username);
          break;
        case "group":
          if (!item.enabled) {
            return;
          }
          this.args.onChangeMode(_constants.MODES.new_group, [item]);
          break;
        default:
          this.router.transitionTo("chat.channel", ...item.model.routeModels);
          this.args.close();
          break;
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "selectChatable", [_object.action]))();
    onFilter(event) {
      this.chatables = [];
      this.term = event?.target?.value;
      this.searchHandler = (0, _debounce.default)(this, this.fetch, event.target.value, _environment.INPUT_DELAY);
    }
    static #_10 = (() => dt7948.n(this.prototype, "onFilter", [_object.action]))();
    async fetch() {
      const loader = new _chatablesLoader.default(this);
      this.chatables = await loader.search(this.term, {
        preloadChannels: !this.term
      });
      this.highlightedChatable = this.items[0];
    }
    static #_11 = (() => dt7948.n(this.prototype, "fetch", [_object.action]))();
    async startOneToOneChannel(username) {
      try {
        const channel = await this.chat.upsertDmChannel({
          usernames: [username]
        });
        if (!channel) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel.routeModels);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    async startGroupChannel(group) {
      try {
        const channel = await this.chat.upsertDmChannel({
          groups: [group]
        });
        if (!channel) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel.routeModels);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ListHandler
          @items={{this.items}}
          @highlightedItem={{this.highlightedChatable}}
          @onHighlight={{this.highlightChatable}}
          @onSelect={{this.selectChatable}}
          @onShifSelect={{this.prefillAddMembers}}
        >
          <div class="chat-message-creator__search-container">
            <div class="chat-message-creator__search">
              <div
                class="chat-message-creator__section"
                {{didInsert (fn this.fetch null)}}
              >
                <SearchInput @filter={{this.term}} @onFilter={{this.onFilter}} />
    
                <DButton
                  class="btn-flat chat-message-creator__search-input__cancel-button"
                  @icon="xmark"
                  @action={{@close}}
                />
              </div>
    
              <List
                @items={{this.items}}
                @highlightedItem={{this.highlightedChatable}}
                @onSelect={{this.selectChatable}}
                @onHighlight={{this.highlightChatable}}
                @onShiftSelect={{this.prefillAddMembers}}
              />
            </div>
          </div>
        </ListHandler>
      
    */
    {
      "id": "lW8InCwd",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@items\",\"@highlightedItem\",\"@onHighlight\",\"@onSelect\",\"@onShifSelect\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"highlightChatable\"]],[30,0,[\"selectChatable\"]],[30,0,[\"prefillAddMembers\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__search-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__search\"],[12],[1,\"\\n          \"],[11,0],[24,0,\"chat-message-creator__section\"],[4,[32,1],[[28,[32,2],[[30,0,[\"fetch\"]],null],null]],null],[12],[1,\"\\n            \"],[8,[32,3],null,[[\"@filter\",\"@onFilter\"],[[30,0,[\"term\"]],[30,0,[\"onFilter\"]]]],null],[1,\"\\n\\n            \"],[8,[32,4],[[24,0,\"btn-flat chat-message-creator__search-input__cancel-button\"]],[[\"@icon\",\"@action\"],[\"xmark\",[30,1]]],null],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[8,[32,5],null,[[\"@items\",\"@highlightedItem\",\"@onSelect\",\"@onHighlight\",\"@onShiftSelect\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"selectChatable\"]],[30,0,[\"highlightChatable\"]],[30,0,[\"prefillAddMembers\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@close\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/search.js",
      "scope": () => [_listHandler.default, _didInsert.default, _helper.fn, _searchInput.default, _dButton.default, _list.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageCreatorSearch;
});