import { template as template_98e58d8b4ea744a489acd07ec470c62b } from "@ember/template-compiler";
const FKLabel = template_98e58d8b4ea744a489acd07ec470c62b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
