define("discourse/plugins/automation/admin/components/fields/da-categories-field", ["exports", "@ember/helper", "@ember/object", "discourse/models/category", "select-kit/components/category-selector", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _object, _category, _categorySelector, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoriesField extends _daBaseField.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable no-redundant-fn }}
        <section class="field categories-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <CategorySelector
                @categories={{this.categories}}
                @onChange={{fn this.onChangeCategories}}
                @options={{hash clearable=true disabled=@field.isDisabled}}
              />
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "FQStUq3v",
      "block": "[[[1,\"\\n\"],[1,\"    \"],[10,\"section\"],[14,0,\"field categories-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@categories\",\"@onChange\",\"@options\"],[[30,0,[\"categories\"]],[28,[32,2],[[30,0,[\"onChangeCategories\"]]],null],[28,[32,3],null,[[\"clearable\",\"disabled\"],[true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-categories-field.js",
      "scope": () => [_daFieldLabel.default, _categorySelector.default, _helper.fn, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    get categories() {
      const ids = this.args.field?.metadata?.value || [];
      return ids.map(id => _category.default.findById(id)).filter(Boolean);
    }
    onChangeCategories(categories) {
      this.mutValue(categories.mapBy("id"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChangeCategories", [_object.action]))();
  }
  _exports.default = CategoriesField;
});