define("discourse/plugins/chat/discourse/components/chat/navbar/full-page-button", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/service", "discourse/components/d-button", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _runloop, _service, _dButton, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarFullPageButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    async openInFullPage() {
      this.chatStateManager.storeAppURL();
      this.chatStateManager.prefersFullPage();
      this.chat.activeChannel = null;
      await new Promise(resolve => (0, _runloop.next)(resolve));
      _url.default.routeTo(this.chatStateManager.lastKnownChatURL);
    }
    static #_3 = (() => dt7948.n(this.prototype, "openInFullPage", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.chatStateManager.isDrawerExpanded}}
          <DButton
            @icon="discourse-expand"
            class="btn-transparent no-text c-navbar__full-page-button"
            @title="chat.open_full_page"
            @action={{this.openInFullPage}}
          />
        {{/if}}
      
    */
    {
      "id": "Wirlq95r",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-transparent no-text c-navbar__full-page-button\"]],[[\"@icon\",\"@title\",\"@action\"],[\"discourse-expand\",\"chat.open_full_page\",[30,0,[\"openInFullPage\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/full-page-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarFullPageButton;
});